import React, { useState, useEffect } from "react";
import Header from "../components/header/header";
import Main from "../components/main/main";
import Footer from "../components/footer/footer";
import Search from "../components/search/search";
import "@fontsource/open-sans";
import "@fontsource/open-sans/300.css";
import Login from "../components/login";

const IndexPage = () => {
  const [isLogIn, setIsLogIn] = useState(false);
  const [buttonText, setButtonText] = useState("Login");
  const [search, setSearch] = useState("");
  const [userData, setUserData] = useState([]);
  useEffect(() => {
    fetch("https://email-server-liart.vercel.app/data").then((response) =>
      response.json().then((resultData) => setUserData(resultData))
    );
  }, []);

  const onSubmit = async (data) => {
    await fetch("https://email-server-liart.vercel.app/login", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status === 200) {
          setIsLogIn(true);
        } else {
          setButtonText("Eingabe Falsch");
        }
      })
      .catch(() => alert("Fehler!"));
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const filteredData = userData.filter((user) => {
    return user.name.toLowerCase().includes(search.toLowerCase());
  });

  return (
    <main>
      <Header />
      {isLogIn ? (
        <div>
          {" "}
          <Search search={handleSearch} />
          <Main DATA={filteredData} />
        </div>
      ) : (
        <Login onSubmit={onSubmit} buttonText={buttonText} />
      )}
      <Footer />
    </main>
  );
};

export default IndexPage;
