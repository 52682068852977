import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Textarea,
  RadioButton,
  Radio,
  Container,
  IconButton,
  Form,
} from "./styles";
import { FiCheckCircle } from "react-icons/fi";
import { UpdatedMsg } from "../common/sendMessage";

const DeviceRepair = ({ cannotRepairMessage, repairPartsNeed, id }) => {
  const { register, watch } = useForm();
  const buttonWatchRepair = watch("deviceRepair", false);
  const buttonWatchRepairParts = watch("repairParts", false);

  return (
    <Container>
      <RadioButton>
        <label htmlFor="deviceRepair">kann Gerät repariert werden?</label>
        <Radio type="radio" {...register("deviceRepair")} value="yes" />
        Ja
        <Radio type="radio" {...register("deviceRepair")} value="no" />
        Nein
      </RadioButton>
      {buttonWatchRepair === "yes" && (
        <RadioButton>
          <label htmlFor="repairParts">Ersatzteile benötigen?</label>
          <Radio type="radio" {...register("repairParts")} defaultValue="yes" />
          Ja
          <Radio type="radio" {...register("repairParts")} defaultValue="no" />
          Nein
          <Radio
            type="radio"
            {...register("repairParts")}
            defaultValue="thinking"
          />
          Default
        </RadioButton>
      )}
      {buttonWatchRepair === "no" && (
        <NoRepair id={id} cannotRepairMessage={cannotRepairMessage} />
      )}
      {buttonWatchRepairParts === "yes" && (
        <RepairWithParts repairPartsNeed={repairPartsNeed} id={id} />
      )}
      {buttonWatchRepairParts === "no" && <RepairStart id={id} />}
    </Container>
  );
};

const NoRepair = ({ cannotRepairMessage, id }) => {
  const { register, watch, handleSubmit } = useForm();
  const showButtonCannotRepair = watch("cannotRepair", false);
  const [isSent, setIsSent] = useState(false);
  const [button, setButton] = useState("");

  const notRepair = async (data) => {
    setButton("value");
    await fetch("https://email-server-liart.vercel.app/repairDevice", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(() => setIsSent(true))
      .then((response) => console.log(response))
      .catch((error) => console.log(error));
  };
  return (
    <div>
      {isSent ? (
        <UpdatedMsg />
      ) : (
        <Form onSubmit={handleSubmit(notRepair)}>
          <input type="hidden" {...register("id")} value={id} />
          <label htmlFor="repairParts">
            Grund für nicht reparierbar
            <Textarea
              type="text"
              {...register("cannotRepair")}
              placeholder="Grund für nicht reparierbar"
              defaultValue={cannotRepairMessage}
            />
          </label>
          {showButtonCannotRepair && (
            <IconButton title="senden" disabled={button ? true : ""}>
              <FiCheckCircle size={30} color="green" />
            </IconButton>
          )}
        </Form>
      )}
    </div>
  );
};

const RepairWithParts = ({ repairPartsNeed, id }) => {
  const { register, watch, handleSubmit } = useForm();
  const showButtonRepairPartsNeed = watch("repairPartsNeed", false);
  const [isSent, setIsSent] = useState(false);
  const [button, setButton] = useState("");

  const updateRepairPartNeed = async (data) => {
    setButton("value");
    await fetch("https://email-server-liart.vercel.app/repairDevice", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(() => setIsSent(true))
      .then((response) => console.log(response))
      .catch((error) => console.log(error));
  };
  return (
    <div>
      {isSent ? (
        <UpdatedMsg />
      ) : (
        <Form onSubmit={handleSubmit(updateRepairPartNeed)}>
          <input type="hidden" {...register("id")} value={id} />
          <label htmlFor="repairPartsNeed">
            Anmerkung
            <Textarea
              type="text"
              {...register("repairPartsNeed")}
              placeholder="Anmerkungen"
              defaultValue={repairPartsNeed}
            />
          </label>
          {showButtonRepairPartsNeed && (
            <IconButton title="senden" disabled={button ? true : ""}>
              <FiCheckCircle size={30} color="green" />
            </IconButton>
          )}
        </Form>
      )}
    </div>
  );
};

const RepairStart = ({ id }) => {
  const { register, handleSubmit } = useForm();
  const [isSent, setIsSent] = useState(false);
  const [button, setButton] = useState("");

  const proceedRepair = async (data) => {
    setButton("value");
    await fetch("https://email-server-liart.vercel.app/repairDevice", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(() => setIsSent(true))
      .then((response) => console.log(response))
      .catch((error) => console.log(error));
  };

  return (
    <div>
      {isSent ? (
        <UpdatedMsg />
      ) : (
        <Form
          style={{ display: "flex", width: "40%", alignItems: "center" }}
          onSubmit={handleSubmit(proceedRepair)}
        >
          <h2>Fortfahren mit reparatur ?</h2>
          <input type="hidden" {...register("id")} value={id} />
          <IconButton title="senden" disabled={button ? true : ""}>
            <FiCheckCircle size={30} color="green" />
          </IconButton>
        </Form>
      )}
    </div>
  );
};

export default DeviceRepair;
