import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Form,
  RadioButton,
  Radio,
  Input,
  IconButton,
  Textarea,
} from "./styles";
import { FiCheckCircle } from "react-icons/fi";
import { UpdatedMsg } from "../common/sendMessage";

const CostEstimate = ({
  ourCostEstimate,
  ourCostEstimateText,
  id,
  email,
  contractNum,
}) => {
  const { register, watch, handleSubmit } = useForm();
  const showCostEstimate = watch("customerCostEstimate", false);
  const buttonWatch = watch("ourCostEstimate", false);
  const [isSent, setIsSent] = useState(false);
  const [button, setButton] = useState("");

  const costUpdate = async (data) => {
    setButton("value");
    await fetch("https://email-server-liart.vercel.app/costEstimate", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(() => setIsSent(true))
      .then((response) => console.log(response))
      .catch((error) => console.log(error));
  };

  return (
    <div>
      {isSent ? (
        <UpdatedMsg />
      ) : (
        <Form onSubmit={handleSubmit(costUpdate)}>
          <input type="hidden" {...register("id")} value={id} />
          <input type="hidden" {...register("email")} value={email} />
          <input
            type="hidden"
            {...register("contractNum")}
            value={contractNum}
          />

          <RadioButton>
            <label htmlFor="customerCostEstimate">
              Kostenvoranschlag Schicken?
            </label>
            <Radio
              type="radio"
              {...register("customerCostEstimate")}
              defaultValue="yes"
            />
            Ja
            <Radio
              type="radio"
              {...register("customerCostEstimate")}
              defaultValue="no"
            />
            Nein
          </RadioButton>
          {showCostEstimate === "yes" && (
            <div style={{ marginTop: "1rem" }}>
              <div style={{ marginBottom: "1.2rem" }}>
                <div>
                  <input
                    type="radio"
                    {...register("error")}
                    defaultValue="power"
                  />
                  <label htmlFor="Stromversorgung">Stromversorgung</label>
                </div>
                <div>
                  <input
                    type="radio"
                    {...register("error")}
                    defaultValue="charge"
                  />
                  <label htmlFor="Ladeelektronik">Ladeelektronik</label>
                </div>
                <div>
                  <input
                    type="radio"
                    {...register("error")}
                    defaultValue="bios"
                  />
                  <label htmlFor="BIOS">BIOS</label>
                </div>
                <div>
                  <input
                    type="radio"
                    {...register("error")}
                    defaultValue="water"
                  />
                  <label htmlFor="Flüssigkeitsschaden">
                    Flüssigkeitsschaden
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    {...register("error")}
                    defaultValue="graphic"
                  />
                  <label htmlFor="Grafikchip">Grafikchip</label>
                </div>
                <div>
                  <input
                    type="radio"
                    {...register("error")}
                    defaultValue="display"
                  />
                  <label htmlFor="Display Defect">Display Defect</label>
                </div>
                <div>
                  <input
                    type="radio"
                    {...register("error")}
                    defaultValue="socket"
                  />
                  <label htmlFor="Strombuchse">Strombuchse</label>
                </div>
                <div>
                  <input
                    type="radio"
                    {...register("error")}
                    defaultValue="housing"
                  />
                  <label htmlFor="Schaden am Gehäuse">Schaden am Gehäuse</label>
                </div>
              </div>
              <label htmlFor="ourCostEstimateText">
                Sonstiges Kostenvoranschlag:
                <Textarea
                  type="text"
                  {...register("ourCostEstimateText")}
                  placeholder="Sonstige"
                  defaultValue={ourCostEstimateText}
                />
              </label>
              <label
                htmlFor="ourCostEstimate"
                style={{
                  width: "55%",
                  display: "flex",
                  marginTop: "1rem",
                }}
              >
                Unsere Kostenvoranschlag:
                <b style={{ margin: "0 1rem", fontSize: "2.7em" }}>€</b>
                <Input
                  style={{ margin: "auto 1rem" }}
                  type="number"
                  {...register("ourCostEstimate")}
                  placeholder="Kostenvoranschlag"
                  defaultValue={ourCostEstimate}
                />
                {buttonWatch && (
                  <IconButton title="Senden" disabled={button ? true : ""}>
                    <FiCheckCircle size={30} color="green" />
                  </IconButton>
                )}
              </label>
            </div>
          )}
        </Form>
      )}
    </div>
  );
};

export default CostEstimate;
