import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  font-family: "Open Sans";
  margin: 1rem auto;
`;

export const Form = styled.form`
  width: 100%;
  font-family: "Open Sans";
  margin: 1rem auto;
`;

export const Input = styled.input`
  font-size: 1.2rem;
  border: none;
  background: #eeeeee;
  border-radius: 0.3rem;
  text-align: center;
  font-family: "Open Sans";
  font-weight: 300;
  display: grid;
  place-items: center;
  width: 100%;
  height: 2.4rem;
  margin-top: 0.6rem;
`;

export const Radio = styled.input`
  height: 1.2rem;
  width: 1.2rem;
  margin: 0.6rem;
`;

export const Textarea = styled.textarea`
  font-size: 1.2rem;
  border: none;
  background: #eeeeee;
  border-radius: 0.3rem;
  text-align: center;
  font-family: "Open Sans";
  font-weight: 300;
  display: grid;
  place-items: center;
  resize: none;
  width: 100%;
  padding-top: 1rem;
  height: 6rem;
  margin-top: 0.6rem;
`;

export const Fieldset = styled.fieldset`
  border: 1px solid ${(props) => props.color || "#e76829"};
  border-radius: 0.3rem;
  margin: 1rem auto;
`;

export const Legend = styled.legend`
  font-family: "Open Sans";
  font-weight: 300;
  color: #252525;
  font-size: 0.8rem;
`;

export const Info = styled.div`
  display: grid;
  gap: 1.8rem;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
  margin: 1rem 2rem;
`;

export const InfoPrice = styled(Info)`
  grid-template-columns: 4fr 1fr;
  margin: 1rem 0;
`;

export const RadioButton = styled.div`
  display: flex;
  align-items: center;
`;

export const SubmitButton = styled.input`
  display: block;
  text-transform: uppercase;
  font-weight: 300;
  letter-spacing: 0.1rem;
  margin: 2rem auto 3rem auto;
  background: #58cced;
  border: none;
  outline: none;
  font-family: "Open Sans";
  font-size: 1.5rem;
  color: white;
  cursor: pointer;
  padding: 0.6rem 3rem;
  border-radius: 0.3rem;
  &:hover {
    color: white;
    background: #3895d3;
  }
`;

export const IconButton = styled.button`
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  display: block;
  margin: 1rem auto;
`;
