import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Textarea, IconButton, Form } from "./styles";
import { FiCheckCircle } from "react-icons/fi";
import { UpdatedMsg } from "../common/sendMessage";

const DeviceAnalysis = ({ deviceAnalysisResult, id }) => {
  const { register, watch, handleSubmit } = useForm();

  const buttonWatch = watch("deviceAnalysisResult", false);
  const [isSent, setIsSent] = useState(false);
  const [button, setButton] = useState("");

  const resultUpdate = async (data) => {
    setButton("value");
    await fetch("https://email-server-liart.vercel.app/deviceAnalysis", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(() => setIsSent(true))
      .then((response) => console.log(response))
      .catch((error) => console.log(error));
  };

  return (
    <div>
      {isSent ? (
        <UpdatedMsg />
      ) : (
        <Form onSubmit={handleSubmit(resultUpdate)}>
          <input type="hidden" {...register("id")} value={id} />
          <label htmlFor="deviceAnalysisResult">
            Ergebnis der Geräteanalyse
            <Textarea
              type="text"
              {...register("deviceAnalysisResult")}
              placeholder="Ergebnis der Geräteanalyse"
              defaultValue={deviceAnalysisResult}
            />
          </label>
          {buttonWatch && (
            <IconButton title="Senden" disabled={button ? true : ""}>
              <FiCheckCircle size={30} color="green" />
            </IconButton>
          )}
        </Form>
      )}
    </div>
  );
};

export default DeviceAnalysis;
