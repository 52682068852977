import React, { useState } from "react";
import { Link } from "gatsby";
import { useForm } from "react-hook-form";
import { Form, InfoPrice, Input, Textarea } from "../forms/styles";
import { FiPlusCircle } from "react-icons/fi";
import { Button, Sup, PostBox } from "./styles";
import { UpdatedMsg } from "../common/sendMessage";

const ToLink = ({ customerData }) => {
  return (
    <div style={{ textAlign: "center" }}>
      <UpdatedMsg />
      <Link
        to="/rechnung"
        state={{ data: customerData }}
        style={{ textDecoration: "none" }}
      >
        <button
          style={{
            textAlign: "center",
            margin: "1rem auto",
            background: "#E76829",
            color: "white",
            border: "none",
            borderRadius: ".3rem",
            padding: ".6rem 1.5rem",
            fontFamily: "Open Sans",
            fontSize: "1.2rem",
            fontWeight: "300",
            cursor: "pointer",
            width: "15rem",
            display: "block",
          }}
        >
          zum Beleg
        </button>
      </Link>
    </div>
  );
};

const ReceiptData = ({
  defect,
  repairItems,
  repairPrice,
  receiptNumber,
  repairItems1,
  repairPrice1,
  repairItems2,
  repairPrice2,
  repairItems3,
  repairPrice3,
  id,
}) => {
  const [customerData, setCustomerData] = useState([]);
  const [first, setFirst] = useState(false);
  const [second, setSecond] = useState(false);
  const [third, setThird] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [button, setButton] = useState("");
  const [buttonText, setButtonText] = useState("Rechnung Erstellen");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const createReceipt = async (data) => {
    setButton("value");
    setButtonText("Warten");
    await fetch("https://email-server-liart.vercel.app/createReceipt", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((resultData) => setCustomerData(resultData))
      .then(() => setIsSent(true))
      .catch((error) => console.log(error));
  };

  return (
    <div>
      {isSent ? (
        <ToLink customerData={customerData} />
      ) : (
        <Form onSubmit={handleSubmit(createReceipt)}>
          <input type="hidden" {...register("id")} value={id} />
          <label htmlFor="Fehlerbild">
            Fehlerbild
            <Textarea
              type="textarea"
              {...register("newDefect")}
              placeholder="fehlerbild"
              defaultValue={defect}
            />
          </label>
          <InfoPrice>
            <label htmlFor="Reparatur">
              Reparatur
              <Input
                type="text"
                {...register("repairItems", { required: true })}
                placeholder="Reparatur"
                defaultValue={repairItems}
              />
              {errors.repairItems && (
                <span style={{ color: "red" }}>Bitte reparatur eingeben!</span>
              )}
            </label>
            <label htmlFor="repairPrice">
              Einzelpreis (Brutto)
              <Input
                type="number"
                {...register("repairPrice", { required: true })}
                defaultValue={repairPrice}
                placeholder="€"
              />
              {errors.repairPrice && (
                <span style={{ color: "red" }}>Bitte Price eingeben!</span>
              )}
            </label>

            <FiPlusCircle
              color="green"
              size={27}
              style={{ margin: "-1.2rem auto -1.2rem auto", cursor: "pointer" }}
              onClick={() => setFirst(!first)}
            />
          </InfoPrice>
          {first && (
            <InfoPrice>
              <label htmlFor="Reparatur1">
                Reparatur 1
                <Input
                  type="text"
                  {...register("repairItems1", { required: true })}
                  placeholder="Reparatur"
                  defaultValue={repairItems1}
                />
                {errors.repairItems1 && (
                  <span style={{ color: "red" }}>
                    Bitte reparatur eingeben!
                  </span>
                )}
              </label>
              <label htmlFor="repairPrice1">
                Einzelpreis (Brutto)
                <Input
                  type="number"
                  {...register("repairPrice1", { required: true })}
                  defaultValue={repairPrice1}
                  placeholder="€"
                />
                {errors.repairPrice1 && (
                  <span style={{ color: "red" }}>Bitte Price eingeben!</span>
                )}
              </label>

              <FiPlusCircle
                color="green"
                size={27}
                style={{
                  margin: "-1.2rem auto -1.2rem auto",
                  cursor: "pointer",
                }}
                onClick={() => setSecond(!second)}
              />
            </InfoPrice>
          )}
          {second && (
            <InfoPrice>
              <label htmlFor="Reparatur2">
                Reparatur 2
                <Input
                  type="text"
                  {...register("repairItems2", { required: true })}
                  placeholder="Reparatur"
                  defaultValue={repairItems2}
                />
                {errors.repairItems2 && (
                  <span style={{ color: "red" }}>
                    Bitte reparatur eingeben!
                  </span>
                )}
              </label>
              <label htmlFor="repairPrice">
                Einzelpreis (Brutto)
                <Input
                  type="number"
                  {...register("repairPrice2", { required: true })}
                  defaultValue={repairPrice2}
                  placeholder="€"
                />
                {errors.repairPrice2 && (
                  <span style={{ color: "red" }}>Bitte Price eingeben!</span>
                )}
              </label>

              <FiPlusCircle
                color="green"
                size={27}
                style={{
                  margin: "-1.2rem auto -1.2rem auto",
                  cursor: "pointer",
                }}
                onClick={() => setThird(!third)}
              />
            </InfoPrice>
          )}
          {third && (
            <InfoPrice>
              <label htmlFor="Reparatur3">
                Reparatur 3
                <Input
                  type="text"
                  {...register("repairItems3", { required: true })}
                  placeholder="Reparatur"
                  defaultValue={repairItems3}
                />
                {errors.repairItems3 && (
                  <span style={{ color: "red" }}>
                    Bitte reparatur eingeben!
                  </span>
                )}
              </label>
              <label htmlFor="repairPrice">
                Einzelpreis (Brutto)
                <Input
                  type="number"
                  {...register("repairPrice3", { required: true })}
                  defaultValue={repairPrice3}
                  placeholder="€"
                />
                {errors.repairPrice3 && (
                  <span style={{ color: "red" }}>Bitte Price eingeben!</span>
                )}
              </label>
            </InfoPrice>
          )}
          <label htmlFor="information">
            Rechnungsnummer
            <Input
              style={{ height: "2.4rem" }}
              type="number"
              {...register("receiptNumber", {
                required: true,
                minLength: 6,
                maxLength: 6,
              })}
              placeholder="Rechnungsnummer"
              defaultValue={receiptNumber}
            />
            {errors.receiptNumber && (
              <span style={{ color: "red" }}>
                Bitte nur 6 Stellige Zahl eingeben!
              </span>
            )}
          </label>
          <div style={{ marginTop: "2.4rem"}}>Versandkosten</div>
          <PostBox>
          <div>
          <input type="radio" {...register("post", {required: true})} value="8.33"/>
          <label htmlFor="Standard">Standard <Sup>€ 8,33</Sup></label>
          </div>
          <div>
          <input type="radio" {...register("post", {required: true})} value="12.20"/>
          <label htmlFor="XL">XL <Sup>€ 12,20</Sup></label>
          </div>
          <div>
          <input type="radio" {...register("post", {required: true})} value="13.40"/>
          <label htmlFor="Express">Express <Sup>€ 13,40</Sup></label>
          </div>
          </PostBox>
          <Button
            type="submit"
            value={buttonText}
            disabled={button ? true : ""}
          />
        </Form>
      )}
    </div>
  );
};

export default ReceiptData;
