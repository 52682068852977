import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  font-family: "Open Sans";
  padding-top: 6rem;
`;

export const Header = styled.h3`
  text-align: center;
  padding-bottom: 3rem;
  font-weight: 600;
`;

export const Form = styled.form`
  width: 300px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  margin: 0 auto;
  background: #eee;
  padding: 6rem 3rem;
  border-radius: 0.6rem;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
`;

export const Label = styled.label`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  margin: 0.3rem 0;
`;

export const Input = styled.input`
  margin: 0.3rem 0;
  border: none;
  border-radius: 0.3rem;
  background: white;
  padding: 0.3rem;
`;

export const Button = styled.input`
  margin-top: 1.2rem;
  border: none;
  border-radius: 0.3rem;
  background: #e76727;
  padding: 0.3rem 0.6rem;

  &:hover {
    color: white;
    background: black;
  }
`;

export const Text = styled.sub`
  font-family: "Open Sans";
  color: red;
`;
