import React from "react";
import styled from "styled-components";
import { FcSearch } from "react-icons/fc";

const Content = styled.div`
  max-width: 800px;
  margin: 1rem auto 5rem auto;
`;

const Form = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Input = styled.input`
  border: none;
  background: #eeeeee;
  padding: 1rem 6rem;
  border-radius: 0.5rem;
  margin-right: 0.3rem;
  width: 98%;
  font-size: 1.5rem;
  font-family: "Open Sans";
  font-weight: 300;
  transition: all 0.2s;

  &:focus {
    outline: none;
    width: 100%;
    background: #d7d7d7;
  }

  &::placeholder {
    font-weight: 300;
    color: hsla(0, 0, 0, 0.5);
    text-transform: uppercase;
    text-align: center;
  }
`;

const Button = styled.button`
  border: none;
  cursor: pointer;
  background: none;
  transition: all 0.2s;
  &:hover {
    margin-left: 1rem;
    transform: scale(1.5);
  }
  &:active {
    outline: transparent;
  }
`;

const Search = ({ search }) => {
  return (
    <Content>
      <Form>
        <label htmlFor="search">
          <Input
            type="search"
            name="search"
            placeholder="Suchen"
            onChange={search}
          />
        </label>
        <Button>
          <FcSearch size={45} />
        </Button>
      </Form>
    </Content>
  );
};

export default Search;
