import React, { useState } from "react";
import dateFormat from "dateformat";
import { FiPlusCircle, FiMinusCircle } from "react-icons/fi";
import Confirmation from "../forms/confirmation";
import CostEstimate from "../forms/costEstimate";
import DataDisplay from "../forms/dataDisplay";
import DeviceAnalysis from "../forms/deviceAnalysis";
import DeviceRepair from "../forms/deviceRepair";
import Information from "../forms/information";
import ReceiptData from "../receipt/data";
import SendReceipt from "../receipt/sendReceipt";

import {
  Content,
  Container,
  Header,
  P,
  Button,
  Fieldset,
  Legend,
  Select,
  Th,
  Td,
  Thead,
} from "./styles";

const Main = ({ DATA }) => {
  var now = new Date();
  var todayString = dateFormat(now, "dd/mm/yyyy");

  return (
    <Content>
      <P>
        Datum: <b>{todayString}</b>
      </P>
      <table style={{ width: "100%" }}>
        <Thead>
          <tr>
            <Th style={{ width: "100px" }}>Auswahl</Th>
            <Th style={{ width: "100px" }}>Datum</Th>
            <Th style={{ width: "100px" }}>Auftrag #</Th>
            <Th style={{ textAlign: "left", paddingLeft: ".9rem" }}>Kunden</Th>
            <Th style={{ textAlign: "left", paddingLeft: ".9rem" }}>Geräte</Th>

            <Th style={{ width: "100px" }}>Mehr</Th>
          </tr>
        </Thead>
      </table>

      {DATA.map((item, index) => (
        <div key={index} style={{ margin: 0 }}>
          <Display
            name={item.name}
            time={item.date}
            num={item.contractNum}
            address={item.address}
            phone={item.phone}
            email={item.email}
            device={item.device}
            model={item.model}
            serial={item.serial}
            repair={item.repair}
            acc={item.acc}
            otheracc={item.otheracc}
            defect={item.defect}
            otherdef={item.otherdef}
            condition={item.condition}
            othercon={item.othercon}
            price={item.price}
            clean={item.clean}
            deviceInformation={item.deviceInformation}
            deviceInformationInternal={item.deviceInformationInternal}
            id={item._id}
            cannotRepairMessage={item.cannotRepairMessage}
            repairPartsNeed={item.repairPartsNeed}
          />
        </div>
      ))}
    </Content>
  );
};

const Display = ({
  name,
  time,
  num,
  address,
  phone,
  email,
  device,
  model,
  serial,
  repair,
  acc,
  otheracc,
  defect,
  otherdef,
  condition,
  othercon,
  price,
  clean,
  deviceInformation,
  deviceInformationInternal,
  id,
  receiveEmailSend,
  receiveEmailSendWithChangesMessage,
  deviceAnalysisResult,
  ourCostEstimate,
  ourCostEstimateText,
  repairPartsNeed,
  cannotRepairMessage,
  repairItems,
  repairPrice,
  impInfo,
  repairItems1,
  repairPrice1,
  repairItems2,
  repairPrice2,
  repairItems3,
  repairPrice3,
}) => {
  const [isClicked, setIsClicked] = useState(false);
  const [option, setOption] = useState("");

  const newTime = dateFormat(time, "dd/mm/yyyy");

  const handleOption = (event) => {
    setOption(event.target.value);
  };
  return (
    <Container>
      <Header>
        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <Td style={{ width: "100px" }}>
                <Button title="ist Erledigt?">
                  <input type="checkbox" name="checkbox" />
                </Button>
              </Td>
              <Td style={{ width: "100px" }}> {newTime}</Td>
              <Td style={{ width: "100px" }}>{num}</Td>
              <Td style={{ textAlign: "left", paddingLeft: ".9rem" }}>
                <b>{name}</b>{" "}
              </Td>
              <Td style={{ textAlign: "left", paddingLeft: ".9rem" }}>
                {" "}
                {device}
              </Td>

              <Td style={{ width: "100px" }}>
                {" "}
                <Button onClick={() => setIsClicked(!isClicked)}>
                  {isClicked ? (
                    <FiMinusCircle
                      title="Alles reduzieren"
                      size={20}
                      color="red"
                    />
                  ) : (
                    <FiPlusCircle
                      title="Alles Anzeigen"
                      size={20}
                      color="green"
                    />
                  )}
                </Button>
              </Td>
            </tr>
          </tbody>
        </table>
      </Header>
      {isClicked && (
        <Container>
          <Information
            deviceInformation={deviceInformation}
            id={id}
            deviceInformationInternal={deviceInformationInternal}
          />
          <Select value={option} onChange={handleOption}>
            <option value="" hidden>
              Auftrag bearbeiten
            </option>
            <option value={1}>Kunden Daten</option>
            <option value={2}>Geräte-Analyse</option>
            <option value={3}>Status Werkstatt</option>
            <option value={4}>Rechnung Erstellen</option>
            <option value={5}>Rechnung Schicken</option>
          </Select>

          {option === "1" ? (
            <DataDisplay
              name={name}
              address={address}
              phone={phone}
              email={email}
              device={device}
              model={model}
              serial={serial}
              repair={repair}
              acc={acc}
              otheracc={otheracc}
              defect={defect}
              otherdef={otherdef}
              condition={condition}
              othercon={othercon}
              price={price}
              clean={clean}
              id={id}
            />
          ) : option === "2" ? (
            <Fieldset color="#2a9df4">
              <Legend>Geräteanalyse</Legend>
              <Confirmation
                name={name}
                email={email}
                model={model}
                device={device}
                serial={serial}
                id={id}
                phone={phone}
                contractNum={num}
                receiveEmailSend={receiveEmailSend}
                receiveEmailSendWithChangesMessage={
                  receiveEmailSendWithChangesMessage
                }
              />
              <DeviceAnalysis
                deviceAnalysisResult={deviceAnalysisResult}
                id={id}
              />
              <CostEstimate
                ourCostEstimate={ourCostEstimate}
                ourCostEstimateText={ourCostEstimateText}
                id={id}
                email={email}
                contractNum={num}
              />
            </Fieldset>
          ) : option === "3" ? (
            <Fieldset color="green">
              <Legend>Geräte-Reparaturzustand Werkstatt</Legend>
              <DeviceRepair
                id={id}
                repairPartsNeed={repairPartsNeed}
                cannotRepairMessage={cannotRepairMessage}
              />
            </Fieldset>
          ) : option === "4" ? (
            <Fieldset color="orange" style={{ padding: "0 1.8rem" }}>
              <Legend>Rechnung Erstellen</Legend>
              <ReceiptData
                id={id}
                defect={defect}
                repairItems={repairItems}
                repairPrice={repairPrice}
                impInfo={impInfo}
                repairItems1={repairItems1}
                repairItems2={repairItems2}
                repairItems3={repairItems3}
                repairPrice1={repairPrice1}
                repairPrice2={repairPrice2}
                repairPrice3={repairPrice3}
              />
            </Fieldset>
          ) : option === "5" ? (
            <Fieldset color="red" style={{ padding: "3rem 1.8rem" }}>
              <Legend>Rechnung Schicken</Legend>
              <SendReceipt
                id={id}
                name={name}
                email={email}
                contractNum={num}
                device={device}
              />
            </Fieldset>
          ) : (
            ""
          )}
        </Container>
      )}
    </Container>
  );
};

export default Main;
