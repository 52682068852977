import styled from "styled-components";

export const Content = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 2.4rem;
`;

export const Container = styled.div`
  max-width: 1000px;
`;

export const Header = styled.div`
  font-family: "Open Sans";
  margin: 0 auto;
  border-bottom: 1px solid #212121;
`;

export const P = styled.p`
  font-family: "Open Sans";
  margin: 0.3rem;
  color: #252525;
`;

export const Button = styled.button`
  outline: none;
  position: relative;
  background: none;
  display: inline-block;
  border: none;
  cursor: pointer;
`;

export const Fieldset = styled.fieldset`
  border: 1px solid ${(props) => props.color || "#e76829"};
  border-radius: 0.3rem;
  margin: 1rem auto;
`;

export const Legend = styled.legend`
  font-family: "Open Sans";
  font-weight: 300;
  color: #252525;
  font-size: 0.8rem;
`;

export const Select = styled.select`
 font-family: "Open Sans";
  font-weight: 300;
  width: 100%;
  background: #eee;
  height: 2.7rem;
  font-size: 1.5rem;
  border: .5px solid black;
  border-radius: 0.3rem;
  margin: 2rem 0;
  padding: 0.3rem 0 0.3rem 0.5rem;
  appearance: none;
  outline: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
    linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
  background-repeat: no-repeat, repeat;
  /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
  background-position: right .7em top 50%, 0 0;
  /* icon size, then gradient */
  background-size: .65em auto, 100%;
}
  option {
     font-family: "Open Sans";
  font-weight: 300;
    background: #eee;
    border-radius: .3rem;
    &::hover {
    background: red;
  }
  }

  
`;

export const Table = styled.table`
  width: 100%;
`;

export const Thead = styled.thead`
  background: #eee;
  font-family: "Open Sans";
`;

export const Td = styled.td`
  text-align: center;
  width: 300px;
`;

export const Th = styled.th`
  text-align: center;
  width: 300px;
`;
