import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  margin: 2rem auto;
  text-align: center;
  font-family: "Open Sans";
  padding-top: 3rem;
`;

const Footer = () => {
  return (
    <Container>
      <p> &copy; {new Date().getFullYear()} TMC-TEC | Solda GmbH</p>
    </Container>
  );
};

export default Footer;
