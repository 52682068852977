import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Form, Info, Textarea, IconButton, RadioButton, Radio } from "./styles";
import { FiCheckCircle } from "react-icons/fi";
import { UpdatedMsg } from "../common/sendMessage";

const Confirmation = ({
  receiveEmailSendWithChangesMessage,
  id,
  name,
  email,
  model,
  serial,
  device,
  contractNum,
  phone,
}) => {
  const { register, watch, handleSubmit } = useForm();
  const showDeviation = watch("receiveEmailSend", false);
  const showButton = watch("receiveEmailSendWithChangesMessage", false);
  const [isSent, setIsSent] = useState(false);
  const [button, setButton] = useState("");

  const sendEmail = async (data) => {
    setButton("value");
    await fetch("https://email-server-liart.vercel.app/confirmationEmail", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(() => setIsSent(true))
      .then((response) => console.log(response))
      .catch((error) => console.log(error));
  };

  return (
    <Info style={{ margin: 0, gap: "0" }}>
      {isSent ? (
        <UpdatedMsg />
      ) : (
        <Form onSubmit={handleSubmit(sendEmail)}>
          <RadioButton style={{ width: "150%" }}>
            <input type="hidden" {...register("id")} value={id} />
            <input type="hidden" {...register("name")} value={name} />
            <input type="hidden" {...register("email")} value={email} />
            <input type="hidden" {...register("serial")} value={serial} />
            <input type="hidden" {...register("model")} value={model} />
            <input type="hidden" {...register("device")} value={device} />
            <input type="hidden" {...register("phone")} value={phone} />
            <input
              type="hidden"
              {...register("contractNum")}
              value={contractNum}
            />
            <label htmlFor="confirmation">Eingangsbestätigung?</label>
            <Radio
              type="radio"
              {...register("receiveEmailSend")}
              value="yes"
              onChange={handleSubmit(sendEmail)}
            />
            Ja
            <Radio type="radio" {...register("receiveEmailSend")} value="no" />
            Nein
            <Radio
              type="radio"
              {...register("receiveEmailSend")}
              defaultValue="yesWithDeviation"
            />
            Ja mit Abweichungen
          </RadioButton>
          <br />
          {showDeviation === "yesWithDeviation" && (
            <label htmlFor="receiveEmailSendWithChangesMessage">
              Abweichungen
              <Textarea
                type="text"
                {...register("receiveEmailSendWithChangesMessage")}
                placeholder="Abweichungen"
                defaultValue={receiveEmailSendWithChangesMessage}
              />
              {showButton && (
                <IconButton title="senden" disabled={button ? true : ""}>
                  <FiCheckCircle size={30} color="green" />
                </IconButton>
              )}
            </label>
          )}
        </Form>
      )}
    </Info>
  );
};

export default Confirmation;
