import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 2rem;
`;

const Title = styled.h1`
  font-size: 2.4rem;
  font-family: "Open Sans";
  font-weight: 300;
  color: #252525;
  text-transform: uppercase;
`;

const Header = () => {
  return (
    <Container>
      <StaticImage
        src="../../logos/tmc-black-orange.svg"
        alt="TMC logo"
        layout="fixed"
        width={150}
      />
      <Title>Reparatur Auftrag</Title>
      <StaticImage
        src="../../images/solda-black.png"
        alt="Solda logo"
        layout="fixed"
        width={150}
      />
    </Container>
  );
};

export default Header;
