import React from "react";
import styled from "styled-components";

const Text = styled.h3`
  font-family: "Open Sans";
  font-weight: 300;
  text-align: center;
  color: green;
`;

export const UpdatedMsg = () => <Text>Erfolgreich aktualisiert</Text>;
