import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { UpdatedMsg } from "../common/sendMessage";

import {
  Form,
  Fieldset,
  Legend,
  Input,
  Info,
  Textarea,
  SubmitButton,
} from "./styles";
const DataDisplay = ({
  name,
  address,
  phone,
  email,
  device,
  model,
  serial,
  repair,
  acc,
  otheracc,
  defect,
  otherdef,
  condition,
  othercon,
  price,
  clean,
  id,
}) => {
  const { register, handleSubmit } = useForm();
  const [isSent, setIsSent] = useState(false);
  const [button, setButton] = useState("");
  const [btnText, setBtnText] = useState("aktualisieren");

  const dataUpdate = async (data) => {
    setButton("value");
    setBtnText("Warten...");
    await fetch("https://email-server-liart.vercel.app/customerData", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(() => setIsSent(true))
      .then((response) => console.log(response))
      .catch((error) => console.log(error));
  };

  return (
    <div>
      {isSent ? (
        <UpdatedMsg />
      ) : (
        <Form onSubmit={handleSubmit(dataUpdate)}>
          <Fieldset color="#0000ff">
            <Legend>Persönliches Information</Legend>
            <Info>
              <label htmlFor="name">
                Name
                <Input
                  type="text"
                  {...register("name", { required: true })}
                  placeholder="Vollständiger Name"
                  defaultValue={name}
                />
              </label>
              <input type="hidden" {...register("id")} value={id} />
              <label htmlFor="address">
                Adresse
                <Input
                  type="text"
                  {...register("address")}
                  placeholder="Address"
                  defaultValue={address}
                />
              </label>
              <label htmlFor="phone">
                Telefon
                <Input
                  type="text"
                  {...register("phone", { required: true })}
                  placeholder="Phone"
                  defaultValue={phone}
                />
              </label>
              <label htmlFor="email">
                Email
                <Input
                  type="email"
                  {...register("email", { required: true })}
                  placeholder="Email"
                  defaultValue={email}
                />
              </label>
            </Info>
          </Fieldset>
          <Fieldset>
            <Legend>Geräte Information</Legend>
            <Info>
              <label htmlFor="device">
                Geräte - Typ
                <Input
                  type="text"
                  {...register("device")}
                  placeholder="Device"
                  defaultValue={device}
                />
              </label>
              <label htmlFor="model">
                Modellbezeichnung
                <Input
                  type="text"
                  {...register("model")}
                  placeholder="Model"
                  defaultValue={model}
                />
              </label>
              <label htmlFor="serial">
                Seriennummer
                <Input
                  type="text"
                  {...register("serial")}
                  placeholder="Serial"
                  defaultValue={serial}
                />
              </label>
              <label htmlFor="repair">
                bereits repariert?
                <Input
                  type="text"
                  {...register("repair")}
                  placeholder="bereits repariert?"
                  defaultValue={repair}
                />
              </label>
              <label htmlFor="acc">
                Zübehor:
                <Textarea
                  type="text"
                  {...register("acc")}
                  placeholder="Zübehor"
                  defaultValue={acc}
                />
              </label>

              <label htmlFor="otheracc">
                Sonstige Zübehor
                <Textarea
                  type="text"
                  {...register("otheracc")}
                  placeholder="Sonstige Zübehor"
                  defaultValue={otheracc}
                />
              </label>

              <label htmlFor="defect">
                Fehlerbeschreibung
                <Textarea
                  type="text"
                  {...register("defect")}
                  placeholder="Fehlerbeschreibung"
                  defaultValue={defect}
                />
              </label>
              <label htmlFor="otherdef">
                Sonstige Fehlerbeschreibung
                <Textarea
                  type="text"
                  {...register("otherdef")}
                  placeholder="Sonstige Fehlerbeschreibung"
                  defaultValue={otherdef}
                />
              </label>
              <label htmlFor="condition">
                Allgemeiner Zustand
                <Textarea
                  type="text"
                  {...register("condition")}
                  placeholder="Allgemeiner Zustand"
                  defaultValue={condition}
                />
              </label>
              <label htmlFor="othercon">
                Sonstige Zustand
                <Textarea
                  type="text"
                  {...register("othercon")}
                  placeholder="Sonstige Zustands"
                  defaultValue={othercon}
                />
              </label>
              <label htmlFor="price">
                Kostenvoranschlag
                <Input
                  type="text"
                  {...register("price")}
                  placeholder="Kostenvoranschlag"
                  defaultValue={price}
                />
              </label>
              <label htmlFor="clean">
                Reinigung & Wartung ?
                <Input
                  type="text"
                  {...register("clean")}
                  placeholder="Reinigung & Wartung?"
                  defaultValue={clean}
                />
              </label>
            </Info>
          </Fieldset>
          <SubmitButton
            type="submit"
            value={btnText}
            disabled={button ? true : ""}
          />
        </Form>
      )}
    </div>
  );
};

export default DataDisplay;
