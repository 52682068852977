import React from "react";
import { useForm } from "react-hook-form";
import { Button, Container, Form, Header, Input, Label, Text } from "./styles";

const Login = ({ onSubmit, buttonText }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Header>Login</Header>

        <Label htmlFor="userName">
          Username
          <Input
            type="text"
            {...register("username", { required: true })}
            placeholder="Username"
          />
          {errors.username && <Text>Username ist Erfoderlich *</Text>}
        </Label>
        <Label htmlFor="password">
          Passwort
          <Input
            type="password"
            {...register("password", { required: true })}
            placeholder="Passwort"
          />
          {errors.password && <Text>Passwort ist Erfoderlich *</Text>}
        </Label>
        <Button type="submit" value={buttonText} />
      </Form>
    </Container>
  );
};

export default Login;
