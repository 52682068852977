import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { UpdatedMsg } from "../common/sendMessage";
import { Header, ReceiptButton } from "./styles";

const SendReceipt = ({ name, email, device, contractNum, id }) => {
  const { register, handleSubmit } = useForm();
  const [isSent, setIsSent] = useState(false);
  const [button, setButton] = useState("");
  const [btnText, setBtnTxt] = useState("Senden");

  const sendReceipt = async (data) => {
    setBtnTxt("Warten ...");
    setButton("value");
    const formData = new FormData();
    formData.append("rechnung", data.rechnung[0]);
    formData.append("id", data.id);
    formData.append("name", data.name);
    formData.append("email", data.email);
    formData.append("device", data.device);
    formData.append("contractNum", data.contractNum);

    await fetch("https://email-server-liart.vercel.app/sendReceipt", {
      method: "POST",
      body: formData,
    })
      .then(() => setIsSent(true))
      .then((response) => console.log(response))
      .catch((error) => console.log(error));
  };
  return (
    <div>
      {isSent ? (
        <UpdatedMsg />
      ) : (
        <form onSubmit={handleSubmit(sendReceipt)}>
          <Header style={{ fontSize: "1.5rem", fontWeight: "600" }}>
            Rechnung wählen({contractNum})
          </Header>
          <label htmlFor="file-uploads">
            <input
              type="file"
              {...register("rechnung", { required: true })}
              accept="application/pdf"
            />
          </label>
          <input type="hidden" {...register("id")} value={id} />
          <input type="hidden" {...register("name")} value={name} />
          <input type="hidden" {...register("email")} value={email} />
          <input type="hidden" {...register("device")} value={device} />
          <input
            type="hidden"
            {...register("contractNum")}
            value={contractNum}
          />
          <ReceiptButton title="Senden" disabled={button ? true : ""}>
            {btnText}
          </ReceiptButton>
        </form>
      )}
    </div>
  );
};

export default SendReceipt;
