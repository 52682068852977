import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Textarea, IconButton, Form, Fieldset, Legend, Info } from "./styles";
import { FiCheckCircle } from "react-icons/fi";
import { UpdatedMsg } from "../common/sendMessage";

const Information = ({ deviceInformation, deviceInformationInternal, id }) => {
  return (
    <Info style={{ marginLeft: 0 }}>
      <Fieldset color="green" style={{ margin: 0, padding: "0 1.8rem" }}>
        <Legend> Aktuelle Geräte Information</Legend>
        <DeviceInformationForm deviceInformation={deviceInformation} id={id} />
      </Fieldset>
      <Fieldset color="red" style={{ margin: 0, padding: "0 1.8rem" }}>
        <Legend> Zur Internen Verwendung</Legend>
        <DeviceInformationInternalForm
          deviceInformationInternal={deviceInformationInternal}
          id={id}
        />
      </Fieldset>
    </Info>
  );
};

const DeviceInformationForm = ({ deviceInformation, id }) => {
  const { register, watch, handleSubmit } = useForm();
  const buttonWatchInfo = watch("deviceInformation", false);
  const [isSent, setIsSent] = useState(false);
  const [button, setButton] = useState("");

  const onSubmitDeviceInformation = async (data) => {
    setButton("value");
    await fetch("https://email-server-liart.vercel.app/deviceInformation", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(() => setIsSent(true))
      .then((response) => console.log(response))
      .catch((error) => console.log(error));
  };

  return (
    <div>
      {isSent ? (
        <UpdatedMsg />
      ) : (
        <Form onSubmit={handleSubmit(onSubmitDeviceInformation)}>
          <label htmlFor="deviceInformation">
            <ol>
              {deviceInformation.map((item, i) => {
                return (
                  <li key={i}>
                    {item.text}
                    <sup style={{ fontSize: ".69rem", color: "green" }}>
                      {item.date}
                    </sup>
                  </li>
                );
              })}
            </ol>
            <Textarea
              type="text"
              {...register("deviceInformation")}
              placeholder="Aktuell Geräte Information"
            />
            <input type="hidden" {...register("id")} value={id} />
          </label>
          {buttonWatchInfo && (
            <IconButton title="Senden" disabled={button ? true : ""}>
              <FiCheckCircle size={30} color="green" />
            </IconButton>
          )}
        </Form>
      )}
    </div>
  );
};

const DeviceInformationInternalForm = ({ deviceInformationInternal, id }) => {
  const { register, watch, handleSubmit } = useForm();
  const buttonWatchInfoInternal = watch("deviceInformationInternal", false);
  const [isSent, setIsSent] = useState(false);
  const [button, setButton] = useState("");

  const onSubmitDeviceInformationInternal = async (data) => {
    setButton("value");
    await fetch(
      "https://email-server-liart.vercel.app/deviceInformationInternal",
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    )
      .then(() => setIsSent(true))
      .then((response) => console.log(response))
      .catch((error) => console.log(error));
  };
  return (
    <div>
      {isSent ? (
        <UpdatedMsg />
      ) : (
        <Form onSubmit={handleSubmit(onSubmitDeviceInformationInternal)}>
          <label htmlFor="deviceInformationInternal">
            <ol>
              {deviceInformationInternal.map((item, i) => {
                return (
                  <li key={i}>
                    {item.text}{" "}
                    <sup style={{ fontSize: ".69rem", color: "green" }}>
                      {item.date}
                    </sup>
                  </li>
                );
              })}
            </ol>
            <Textarea
              type="text"
              {...register("deviceInformationInternal")}
              placeholder="Internal Information"
            />
            <input type="hidden" {...register("id")} value={id} />
          </label>
          {buttonWatchInfoInternal && (
            <IconButton title="Senden" disabled={button ? true : ""}>
              <FiCheckCircle size={30} color="green" />
            </IconButton>
          )}
        </Form>
      )}
    </div>
  );
};

export default Information;
